import React, { useContext, useEffect, useState } from "react";

import { WarningOutlined } from "@mui/icons-material";
import {
    Alert,
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";

import { CeleriMarkdown } from "../../../../components/celeri/markdown";
import { DeadlinesFooters } from "../../../../components/deadlines/footer";
import UploadFiles from "../../../../components/upload-files";
import { DeadlinesContext } from "../../../../context/deadlines/DeadlinesContext";
import { Entity } from "../../../../interfaces/entities";
import CommonLayout from "../../../../layout/common";

import "./index.scss";
import _ from "lodash";
import { FilePreview } from "../../../../ui/file-preview";
import api from "../../../../api";
import { getDeadlineStatusClassName, getDeadlineStatusLabel } from "../../../../utils";
import { DeadlineStatus } from "../../../../interfaces/api/deadlines";
import useApp from "../../../../hooks/useApp";
import { Attachment } from "../../../../interfaces/api/submissions";
import { UserContext } from "../../../../context/user/UserContext";
import { User } from "../../../../interfaces/user";

type NewParams = {
    id: string;
};

type PropEntityTypes = {
    entity: Entity;
};

const EntityComponent = (props: PropEntityTypes) => {
    const { entity } = props;

    return (
        <Button key={entity.id_entity} color="inherit" sx={{ textTransform: "none", cursor: "pointer" }} variant="text">
            <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {entity.legal_name || ""}
            </span>
        </Button>
    );
};

export const ViewDeadlines = () => {
    const { id } = useParams<NewParams>();
    const { user, organization } = useContext(UserContext);
    const { deadline, viewVersion, setViewVersion, getDeadlineById, createNewVersion, loading } = useContext(
        DeadlinesContext,
    );

    const [observations, setObservations] = useState("");
    const [creatingNewVersion, setCreatingNewVersion] = useState(false);
    const [files, setFiles] = useState<File[] | Attachment[]>([]);

    const getDeadline = async (id: string) => {
        const deadline = await getDeadlineById(id);

        if (deadline && deadline.filings) {
            const last_version = _.maxBy(deadline.filings.versions, "updated_at");

            setViewVersion(last_version);
            setFiles(last_version?.attachment || []);
            setObservations(last_version?.body || "");
        } else {
            setObservations("");
        }
    };

    useEffect(() => {
        getDeadline(id);
    }, [id]);

    useEffect(() => {
        if (viewVersion) {
            setCreatingNewVersion(false);
            setObservations(viewVersion.body);
        }
    }, [viewVersion]);

    const handleCreateNewVersion = (ev: React.MouseEvent) => {
        ev.stopPropagation();
        setViewVersion(undefined);
        setCreatingNewVersion(true);
        setObservations("");
    };

    const handleDeleteFile = (id_attachment: string) => () => {
        if (viewVersion) {
            setViewVersion({
                ...viewVersion,
                attachment: (viewVersion.attachment as Attachment[]).filter((f: Attachment) => f.id !== id_attachment),
            });
        }
    };

    return (
        <div className="pages-view-deadlines">
            <CommonLayout
                loading={loading}
                title={`Vencimiento: ${
                    deadline ? deadline.regulation.name + " " + moment(deadline?.date).format("DD/MM/YYYY") : ""
                }`}
            >
                {loading && (
                    <Backdrop open={loading} sx={{ margin: "auto" }}>
                        {" "}
                        <CircularProgress />
                    </Backdrop>
                )}
                {!loading && deadline && (
                    <Box sx={{ width: "100%" }}>
                        <div className="info-general">
                            <div className="row">
                                <Typography className="row-title">Regulador: </Typography>
                                <Typography>{deadline.regulator.code}</Typography>
                            </div>
                            <div className="row">
                                <Typography className="row-title">Fecha: </Typography>
                                <Typography>{moment(deadline?.date).format("DD/MM/YYYY")}</Typography>
                            </div>
                            <div className="row">
                                <Typography className="row-title">Entidades: </Typography>
                                {deadline.entities.map((entity) => (
                                    <EntityComponent key={entity.id_entity} entity={entity} />
                                ))}
                            </div>
                        </div>
                        <div className="versions">
                            <Typography variant="h6">Versiones</Typography>
                            <Divider className="divider" />
                            {!deadline.filings && <Alert severity="warning">No hay ninguna versión presentada</Alert>}
                            {!!deadline.filings && (
                                <Box
                                    sx={{
                                        marginBottom: "16px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Select
                                            margin="dense"
                                            value={viewVersion?.id_version || "nueva"}
                                            variant="outlined"
                                            onChange={(ev) =>
                                                setViewVersion(
                                                    deadline.filings.versions.find(
                                                        (v) => v.id_version === ev.target.value,
                                                    ),
                                                )
                                            }
                                        >
                                            <MenuItem disabled value={"nueva"}>
                                                Nueva Versión
                                            </MenuItem>
                                            {_.sortBy(deadline.filings.versions, "created_at").map((version: any) => (
                                                <MenuItem key={version.id_version} value={version.id_version}>
                                                    Version {version.number}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {viewVersion && (
                                            <Box
                                                sx={{
                                                    marginLeft: "16px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "start",
                                                }}
                                            >
                                                <Chip
                                                    className={getDeadlineStatusClassName(viewVersion?.status)}
                                                    label={getDeadlineStatusLabel(viewVersion?.status)}
                                                    variant={
                                                        viewVersion?.status === DeadlineStatus.Expired
                                                            ? "filled"
                                                            : "outlined"
                                                    }
                                                />
                                                <Typography sx={{ fontSize: "14px" }} variant="subtitle1">
                                                    <b>creado por:</b>{" "}
                                                    {
                                                        organization?.users.find(
                                                            (p: User) => p.id_party === viewVersion.created_by,
                                                        )?.email
                                                    }
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box>
                                        <Button
                                            color="primary"
                                            disabled={creatingNewVersion}
                                            sx={{ marginLeft: "16px" }}
                                            variant="contained"
                                            onClick={handleCreateNewVersion}
                                        >
                                            Crear nueva versión
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {(!deadline.filings || creatingNewVersion) && (
                                <div className="version-container">
                                    <UploadFiles files={files} setFiles={setFiles} />
                                    <div className="observations">
                                        <TextField
                                            multiline
                                            className="text-area"
                                            label="Observaciones"
                                            minRows={5}
                                            placeholder="Escribe algunas observaciones"
                                            value={observations}
                                            variant="outlined"
                                            onChange={(ev) => setObservations(ev.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                            {deadline.filings && viewVersion && !creatingNewVersion && viewVersion.status === "DRAFT" && (
                                <div className="version-container">
                                    <div className="file-container">
                                        {viewVersion.status == "DRAFT" && (
                                            <UploadFiles files={files} setFiles={setFiles} />
                                        )}
                                    </div>
                                    <div className="observations">
                                        <TextField
                                            multiline
                                            aria-readonly="true"
                                            className="text-area"
                                            label="Observaciones"
                                            minRows={5}
                                            placeholder="Escribe algunas observaciones"
                                            value={observations || viewVersion.body}
                                            variant="outlined"
                                            onChange={(ev) => setObservations(ev.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                            {deadline.filings && viewVersion && !creatingNewVersion && viewVersion.status !== "DRAFT" && (
                                <div className="version-container columns">
                                    <div className="file-container">
                                        {viewVersion.attachment.map((a: Attachment | File) => (
                                            <FilePreview
                                                key={a.name}
                                                file={{ ...a, name: a.name.split("/")[1].slice(37) }}
                                                readOnly={false}
                                                onDelete={handleDeleteFile((a as Attachment).id)}
                                            />
                                        ))}
                                    </div>
                                    <div className="observations">
                                        <TextField
                                            disabled
                                            multiline
                                            aria-readonly="true"
                                            className="text-area"
                                            label="Observaciones"
                                            minRows={5}
                                            placeholder="Escribe algunas observaciones"
                                            value={observations || viewVersion.body}
                                            variant="outlined"
                                            onChange={(ev) => setObservations(ev.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <DeadlinesFooters attachments={files} body={observations} />
                    </Box>
                )}
            </CommonLayout>
        </div>
    );
};
