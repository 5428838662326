import { RequirementsResponse } from "../../interfaces/api/requirements";

export interface RequirementsState {
    errorMessage?: string;
    loading: boolean;
    loadingAction: {
        value: boolean;
        type?: string;
    };
    requirements?: RequirementsResponse[];
    activeRequirements?: RequirementsResponse[];
    inactiveRequirements?: RequirementsResponse[];
    expiredRequirements?: RequirementsResponse[];
    filter?: "active" | "inactive" | "expired" | undefined;
}

type RequirementsActions =
    | { type: "setRequirements"; payload: RequirementsResponse[] | undefined }
    | { type: "setActiveRequirements"; payload: RequirementsResponse[] | undefined }
    | { type: "setInactiveRequirements"; payload: RequirementsResponse[] | undefined }
    | { type: "setexpiredRequirements"; payload: RequirementsResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setLoadingAction"; payload: { value: boolean; type?: string; reqId?: string } }
    | { type: "setFilter"; payload: "active" | "inactive" | "expired" | undefined }
    | { type: "cleanRequirements" }
    | { type: "removeError" };

export const RequirementsReducer = (state: RequirementsState, action: RequirementsActions): RequirementsState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "setFilter":
            return {
                ...state,
                filter: action.payload,
            };
        case "setLoadingAction":
            return {
                ...state,
                loadingAction: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setRequirements":
            return {
                ...state,
                requirements: action.payload,
            };
        case "setActiveRequirements":
            return {
                ...state,
                activeRequirements: action.payload,
            };
        case "setInactiveRequirements":
            return {
                ...state,
                inactiveRequirements: action.payload,
            };
        case "setexpiredRequirements":
            return {
                ...state,
                expiredRequirements: action.payload,
            };
        case "cleanRequirements":
            return {
                ...state,
                requirements: undefined,
            };
        default:
            return state;
    }
};
