import React, { useContext, useEffect, useState } from "react";

import { ContentPaste, PeopleAltOutlined, SystemUpdateAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid";
import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import ImportPreviewDialog from "../../components/import-preview";
import { RequirementsFilters } from "../../components/requirements/filters";
import RequirementsTable from "../../components/requirements/table";
import { RequirementsContext } from "../../context/requirements/RequirementsContext";
import { RequirementImportExcel } from "../../interfaces/requirements";
import CommonLayout from "../../layout/common";

import "./index.scss";
import { UserContext } from "../../context/user/UserContext";

type PropTypes = RouteComponentProps<any>;

interface Action {
    type?: string;
    id?: string;
}

const Requirements = (props: PropTypes) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filters = queryParams.get("filters");

    const getFilterInitialState = (): "active" | "inactive" | "expired" => {
        if (filters === "active" || filters === "inactive" || filters === "expired") {
            return filters;
        }

        return "active";
    };

    const {
        createRequirement,
        getRequirements,
        getRequirementsByFilter,
        loading,
        errorMessage,
        requirements,
        activeRequirements,
        inactiveRequirements,
        expiredRequirements,
    } = useContext(RequirementsContext);
    const { user, organization } = useContext(UserContext);

    const [importData, setImportData] = useState<RequirementImportExcel[] | undefined>();
    const [importBy, setImportBy] = useState<string>("");
    const [customColumns, setCustomColumns] = useState<GridColDef[] | undefined>();
    const [requirementsFilters, setRequirementsFilters] = useState<"active" | "inactive" | "expired" | undefined>(
        getFilterInitialState(),
    );

    const handleAddRequirement = () => {
        props.history.push("/requirements/new");
    };

    const getReqs = async () => {
        if (requirementsFilters === undefined) {
            getRequirements();
        } else {
            getRequirementsByFilter(requirementsFilters);
        }
    };

    useEffect(() => {
        getReqs();
    }, []);

    const handleChangeFilters = (value?: "active" | "inactive" | "expired") => {
        if (value === undefined) {
            getRequirements();
            props.history.push("/requirements");
        } else {
            getRequirementsByFilter(value);
            props.history.push(`/requirements?filters=${value}`);
        }
        setRequirementsFilters(value);
    };

    const handleImportRequirement = (by: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files,
            f = files && files[0];
        const reader = new FileReader();

        if (f) {
            reader.onload = function (e: ProgressEvent<FileReader>) {
                const data = e?.target?.result;
                const readedData = XLSX.read(data, { type: "binary" });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const xlsxData = XLSX.utils.sheet_to_json(ws, { header: "A", raw: false, rawNumbers: false });

                if (xlsxData && xlsxData instanceof Array) {
                    const dataParse = xlsxData.slice(1).map(
                        (o: any): RequirementImportExcel => {
                            const temp: any = {};

                            Object.keys(o).map((k) => (temp[xlsxData[0][k]] = o[k]));

                            return {
                                id_requirement: uuidv4(),
                                ...temp,
                            };
                        },
                    );
                    const customColumns: GridColDef[] = [];

                    Object.keys(xlsxData[0]).map((key) => {
                        if (xlsxData[0][key] === "email") {
                            customColumns.push({
                                field: "email",
                                headerName: "Contacto",
                                width: 200,
                                renderCell: (params: any) => <b>{params.value}</b>,
                            });
                        }
                        if (xlsxData[0][key] === "account") {
                            customColumns.push({
                                field: "account",
                                headerName: "Cuenta",
                                width: 200,
                                renderCell: (params: any) => <b>{params.value}</b>,
                            });
                        } else if (xlsxData[0][key] === "template") {
                            customColumns.push({
                                field: "template",
                                headerName: "Template",
                                width: 300,
                            });
                        } else if (xlsxData[0][key] === "reference") {
                            customColumns.push({
                                field: "reference",
                                headerName: "Detalle",
                                width: 300,
                            });
                        } else {
                            customColumns.push({
                                field: xlsxData[0][key],
                                width: 150,
                            });
                        }
                    });
                    setCustomColumns(customColumns);
                    setImportData(dataParse);
                    setImportBy(by);
                }
            };
            reader.readAsBinaryString(f);
        }
        e.target.value = "";
        handleClose();
    };

    const requirementsByFilter = {
        active: activeRequirements,
        inactive: inactiveRequirements,
        expired: expiredRequirements,
    };

    const isLoading = () => {
        if (loading || (!requirements && requirementsFilters && !requirementsByFilter[requirementsFilters])) {
            return true;
        }

        return false;
    };

    const getRefreshFunction = () => {
        if (requirementsFilters === undefined) {
            return getRequirements();
        }

        return getRequirementsByFilter(requirementsFilters);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="pages-requirements">
            <CommonLayout loading={loading} refreshFunction={getRefreshFunction} title="Requerimientos">
                <div className="options-requirements">
                    <RequirementsFilters value={requirementsFilters} onClick={handleChangeFilters} />
                    <Button
                        disableElevation
                        aria-controls={open ? "demo-customized-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        endIcon={<KeyboardArrowDownIcon />}
                        id="demo-customized-button"
                        variant="contained"
                        onClick={handleClick}
                    >
                        Acciones
                    </Button>
                    <Menu anchorEl={anchorEl} id="basic-menu" open={open} onClose={handleClose}>
                        <MenuItem className="menu-item-button" onClick={handleAddRequirement}>
                            <AddIcon fontSize="small" />
                            <ListItemText>Agregar</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            disableRipple
                            className="menu-item"
                            onClick={() => {
                                window.analytics.track("click_importPerContact_button_requirements", {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                });
                            }}
                        >
                            <ListItemText>
                                <input
                                    hidden
                                    accept={[".csv", ".xlsx", ".xls", "text/plain"].join(",")}
                                    className="hidden"
                                    id="import-requirement-input"
                                    type="file"
                                    onChange={handleImportRequirement("contacts")}
                                />
                                <label className="icon-text" htmlFor="import-requirement-input">
                                    <PeopleAltOutlined sx={{ fontWeight: 300 }} />
                                    Importar por contacto
                                </label>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            disableRipple
                            className="menu-item"
                            onClick={() => {
                                window.analytics.track("click_importPerAccount_button_requirements", {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                });
                            }}
                        >
                            <ListItemText>
                                <span>
                                    <input
                                        hidden
                                        accept={[".csv", ".xlsx", ".xls", "text/plain"].join(",")}
                                        className="hidden"
                                        id="import-requirement-input-cuentas"
                                        type="file"
                                        onChange={handleImportRequirement("accounts")}
                                    />
                                    <label className="icon-text" htmlFor="import-requirement-input-cuentas">
                                        <SystemUpdateAlt sx={{ fontWeight: 300 }} />
                                        Importar por cuenta
                                    </label>
                                </span>
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
                <RequirementsTable
                    handleUpdateRow={getReqs}
                    loading={isLoading()}
                    requirements={
                        requirementsFilters ? requirementsByFilter[requirementsFilters] || [] : requirements || []
                    }
                />
                <ImportPreviewDialog
                    cleanData={() => setImportData(undefined)}
                    columns={customColumns || []}
                    completePreview={async () => {
                        if (importData) {
                            const success = await createRequirement(importData, importBy);

                            if (success) {
                                setImportData(undefined);
                            } else {
                                //falló

                                window.analytics.track(
                                    importBy === "contacts"
                                        ? "failed_importPerContacts_requirements"
                                        : "failed_importPerAccounts_requirements",
                                    {
                                        id_user: user?.id,
                                        id_organization: organization?.id_organization,
                                        errorType: errorMessage,
                                    },
                                );
                            }
                        }
                    }}
                    data={importData}
                    loading={isLoading()}
                />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Requirements);
