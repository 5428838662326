import React, { createContext, useReducer } from "react";

import { isArray } from "lodash";
import { useSnackbar } from "notistack";

import api from "../../api";
import { ContactResponse } from "../../interfaces/api/contacts";
import { Contact } from "../../interfaces/contacts";
import { ContactsReducer, ContactsState } from "./ContactsReducer";

type ContactsContextProps = {
    contacts?: ContactResponse[];
    count?: number;
    errorMessage?: string;
    loading: boolean;
    pageIndex: number;
    pageSize: number;
    searchText: string;
    getContacts: () => Promise<void>;
    getContactsCount: () => Promise<void>;
    setPageIndex: (pageIndex: number) => Promise<void>;
    setPageSize: (pageSize: number) => Promise<void>;
    setSearchText: (searchText: string) => Promise<void>;
    searchContacts: (searchText: string) => Promise<void>;
    getContactById: (id: string) => Promise<Contact | undefined>;
    removeError: () => void;
    cleanContacts: () => void;
    createContact: (contact: Partial<Contact> | Contact[]) => Promise<boolean>;
    updateContact: (contact: Partial<Contact>, id: string) => Promise<boolean>;
    deleteContact: (id_contact: string) => Promise<boolean>;
};

const contactsInitialState: ContactsState = {
    errorMessage: undefined,
    loading: false,
    contacts: undefined,
    count: undefined,
    pageSize: 10,
    pageIndex: 0,
    searchText: "",
};

export const ContactsContext = createContext({} as ContactsContextProps);

export const ContactsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(ContactsReducer, contactsInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
    };

    const onSuccessWithMessage = (successMessage: string) => {
        onSuccess();
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getContacts = async () => {
        /* try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<ContactResponse[]>("/contacts");
            const contacts = resp.data;

            dispatch({
                type: "setContacts",
                payload: contacts || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanContacts();
        } */
    };

    const getContactsCount = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            let resp;

            if (state.searchText) {
                resp = await api.head<any>("/rpc/search_contacts", {
                    params: {
                        q: state.searchText,
                    },
                    headers: {
                        Prefer: "count=exact",
                    },
                });
            } else {
                resp = await api.head<any>("/contacts", {
                    headers: {
                        Prefer: "count=exact",
                    },
                });
            }

            const count = Number(resp.headers["content-range"].split("/")[1]);

            dispatch({
                type: "setContactsCount",
                payload: count,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanContacts();
        }
    };

    const setPageIndex = async (pageIndex: number) => {
        dispatch({
            type: "setPageIndex",
            payload: pageIndex,
        });
    };

    const setPageSize = async (pageSize: number) => {
        dispatch({
            type: "setPageSize",
            payload: pageSize,
        });
    };

    const setSearchText = async (searchText: string) => {
        dispatch({
            type: "setSearchText",
            payload: searchText,
        });
    };

    const searchContacts = async (searchText: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            const resp = await api.get<ContactResponse[]>("/contacts/search", {
                params: { q: searchText },
            });

            const contacts = resp.data;

            dispatch({
                type: "setContacts",
                payload: contacts || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanContacts();
        }
    };

    const getContactById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<ContactResponse>(`/contacts/${id}`);

            onSuccess();

            return resp.data;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const createContact = async (contact: Partial<Contact> | Contact[]) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const mapContact = () => {
                if (isArray(contact)) {
                    return contact.map((c) => ({ ...c, id_contact: undefined }));
                } else {
                    return {
                        ...contact,
                        id_contact: undefined,
                        data: undefined,
                        ...(contact.data || {}),
                    };
                }
            };
            const mappedContact = mapContact();

            await api.post<ContactResponse[]>("/contacts", mappedContact);
            onSuccessWithMessage(
                isArray(contact) ? "Los contactos fueron creados con éxito" : "El contacto fue creado con éxito",
            );
            //getContacts();
            const success = true;

            return success;
        } catch (error: any) {
            onError(error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const updateContact = async (contact: Partial<Contact>, id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            await api.patch<ContactResponse[]>(`/contacts/${id}`, contact);
            onSuccessWithMessage("El contacto ha sido actualizado correctamente");
            //getContacts();
            const success = true;

            return success;
        } catch (error: any) {
            onError(error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const deleteContact = async (id_contact: string) => {
        try {
            /* dispatch({
                type: "setLoading",
                payload: true,
            }); */
            await api.delete<ContactResponse[]>(`/contacts/${id_contact}`);
            onSuccessWithMessage("El contacto ha sido eliminado con éxito");
            //getContacts();
            const success = true;

            return success;
        } catch (error: any) {
            onError(error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanContacts = () => {
        dispatch({ type: "cleanContacts" });
    };

    return (
        <ContactsContext.Provider
            value={{
                ...state,
                getContacts,
                getContactsCount,
                setPageIndex,
                setPageSize,
                setSearchText,
                cleanContacts,
                removeError,
                createContact,
                updateContact,
                getContactById,
                searchContacts,
                deleteContact,
            }}
        >
            {children}
        </ContactsContext.Provider>
    );
};
