import { Icon, IconButton } from "@mui/material";
import React from "react";

import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";

import { Attachment } from "../../interfaces/api/submissions";

import "./index.scss";

interface PropTypes {
    file: File | Attachment;
    onClick?: () => void;
    onDelete?: () => void;
    readOnly?: boolean;
}

export const FilePreview = ({ file, onClick, onDelete, readOnly = true }: PropTypes) => {
    const fileExtension = file.name.split(".")[1];

    const getStyles = () => {
        switch (fileExtension) {
            case "pdf":
            case "txt":
            case "png":
            case "jpg":
            case "jpeg": {
                return defaultStyles[fileExtension];
            }
            case "xls":
            case "xlsx":
            case "doc":
            case "docx": {
                return { ...defaultStyles[fileExtension], color: undefined, foldColor: undefined };
            }
            default: {
                return undefined;
            }
        }
    };

    return (
        <div className="ui-file-preview" onClick={onClick}>
            <FileIcon extension={fileExtension} {...getStyles()} />
            {!readOnly && (
                <IconButton
                    onClick={(ev) => {
                        ev.stopPropagation();
                        onDelete && onDelete();
                    }}
                >
                    <Icon>close</Icon>
                </IconButton>
            )}
            <div>{file.name}</div>
        </div>
    );
};
