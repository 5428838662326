import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { RouteComponentProps, withRouter } from "react-router";

import { TemplatesContext } from "../../context/templates/TemplatesContext";
import CommonLayout from "../../layout/common";
import { Table } from "../../ui/table";
import { getIconByTemplateType, getTemplateTypeLabel } from "../../utils";

import "./index.scss";
import { SearchInput } from "../../ui/search-input";
import { Template } from "../../interfaces/templates";
import { TemplateTypes } from "../../interfaces/api/templates";
import { DialogContext } from "../../context/dialog/DialogContext";

type PropTypes = RouteComponentProps<any>;

const Templates = (props: PropTypes) => {
    const {
        searchTemplate,
        setSearchTemplate,
        setDefaultTemplate,
        templates,
        getTemplates,
        updateTemplate,
        loading,
    } = useContext(TemplatesContext);
    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Nombre",
            width: 470,
        },
        {
            field: "type",
            headerName: "Tipo",
            width: 548,
            valueGetter: (params: GridValueGetterParams) => getTemplateTypeLabel(params.row.type),
        },
        {
            field: "actions",
            headerName: "Acciones",
            sortable: false,
            filterable: false,
            renderCell: (params: any) => (
                <IconButton
                    onClick={(ev: any) => {
                        ev.stopPropagation();
                        handleEditTemplate(params.row.id_template);
                    }}
                >
                    <Icon>edit</Icon>
                </IconButton>
            ),
        },
    ];

    const initialSortModel: GridSortModel = [
        {
            field: "name",
            sort: "asc",
        },
    ];

    useEffect(() => {
        getTemplates();
    }, []);

    const handleAddTemplate = () => {
        openCustomDialog({
            children: <DialogComponent />,
        });
    };

    const DialogComponent = () => {
        const [loading, setLoading] = useState(false);
        const handleAddTemplateType = (type: TemplateTypes) => () => {
            setDefaultTemplate({ type });
            props.history.push(`/templates/new`);
            closeCustomDialog();
        };

        return (
            <DialogContent sx={{ paddingBottom: "8px", marginBottom: "16px" }}>
                <DialogTitle sx={{ paddingLeft: 0, paddingBottom: "8px" }}>Crear template</DialogTitle>
                <DialogContentText sx={{ color: "grey", fontSize: "12px", paddingBottom: "16px" }}>
                    Selecciona el tipo de template que quieres crear:
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={2}>
                        <Button
                            color="primary"
                            startIcon={getIconByTemplateType(TemplateTypes.FileUpload)}
                            sx={{
                                height: 100,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            variant="outlined"
                            onClick={handleAddTemplateType(TemplateTypes.FileUpload)}
                        >
                            {getTemplateTypeLabel(TemplateTypes.FileUpload)}
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={2}>
                        <Button
                            color="primary"
                            startIcon={getIconByTemplateType(TemplateTypes.Signature)}
                            sx={{
                                height: 100,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            variant="outlined"
                            onClick={handleAddTemplateType(TemplateTypes.Signature)}
                        >
                            {getTemplateTypeLabel(TemplateTypes.Signature)}
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={2}>
                        <Button
                            color="primary"
                            startIcon={getIconByTemplateType(TemplateTypes.Form)}
                            sx={{
                                height: 100,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            variant="outlined"
                            onClick={handleAddTemplateType(TemplateTypes.Form)}
                        >
                            {getTemplateTypeLabel(TemplateTypes.Form)}
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={2}>
                        <Button
                            color="primary"
                            startIcon={getIconByTemplateType(TemplateTypes.FormSignature)}
                            sx={{
                                height: 100,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            variant="outlined"
                            onClick={handleAddTemplateType(TemplateTypes.FormSignature)}
                        >
                            {getTemplateTypeLabel(TemplateTypes.FormSignature)}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        );
    };

    const handleEditTemplate = (id: string) => {
        props.history.push(`/templates/${id}`);
    };

    const handleChangeSearch = (searchedText: string) => {
        if (searchedText.length) {
            setSearchTemplate(searchedText);
        } else {
            setSearchTemplate("");
        }
    };

    return (
        <div className="pages-templates">
            <CommonLayout loading={loading} title="Templates">
                <div className="buttons-wrapper">
                    <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
                        <Button
                            startIcon={<AddIcon sx={{ fontWeight: 300 }} />}
                            sx={{ marginRight: "16px", alignItems: "center", padding: "2px 16px", height: 36.5 }}
                            variant="contained"
                            onClick={handleAddTemplate}
                        >
                            Agregar
                        </Button>
                    </Box>
                    <SearchInput
                        value={searchTemplate}
                        onChange={handleChangeSearch}
                        onClickSearch={handleChangeSearch}
                    />
                </div>
                {templates && (
                    <Table
                        columns={columns}
                        getRowClassName={() => "cursor-pointer"}
                        getRowId={(row: any) => row["id_template"]}
                        initialSortModel={initialSortModel}
                        rows={templates.filter((t: Template) =>
                            t.name.toLowerCase().includes(searchTemplate.toLowerCase()),
                        )}
                        onRowClick={(params: any, event: React.MouseEvent) => {
                            event.stopPropagation();
                            props.history.push(`/templates/${params.row.id_template}`);
                        }}
                    />
                )}
            </CommonLayout>
        </div>
    );
};

export default withRouter(Templates);
