import React, { useContext } from "react";

import TemplateForm from "../../../components/templates/form";
import { TemplatesContext } from "../../../context/templates/TemplatesContext";
import CommonLayout from "../../../layout/common";

import "./index.scss";

export const AddTemplate = () => {
    const { defaultTemplate } = useContext(TemplatesContext);

    return (
        <div className="pages-add-template">
            <CommonLayout loading={false} title="Crear Template">
                <TemplateForm template={defaultTemplate} />
            </CommonLayout>
        </div>
    );
};
