import { DeadlinesResponse, Filing } from "../api/deadlines";

export interface Deadline extends Omit<DeadlinesResponse, "filings"> {
    filings: Filing;
}

export interface DeadlineImportExcel {
    id_requirement: string;
    email: string;
    template: string;
    reference: string;
    [key: string]: any;
}

export enum DeadlinesFilterType {
    day = "day",
    week = "week",
    month = "month",
    yaer = "year",
}
