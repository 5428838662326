import React, { useContext, useEffect, useState } from "react";

import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { Button, CircularProgress, Icon, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import api from "../../../api";
import ContactForm from "../../../components/contacts/form";
import EmailsTable from "../../../components/emails/table";
import RequirementsTable from "../../../components/requirements/table";
import SubmissionsTable from "../../../components/submissions/table";
import { ContactsContext } from "../../../context/contacts/ContactsContext";
import { EmailsContext } from "../../../context/emails/EmailsContext";
import { RequirementsContext } from "../../../context/requirements/RequirementsContext";
import { SubmissionsContext } from "../../../context/submissions/SubmissionsContext";
import { EmailsResponse } from "../../../interfaces/api/emails";
import { RequirementsResponse } from "../../../interfaces/api/requirements";
import { SubmissionsResponse } from "../../../interfaces/api/submissions";
import { Contact } from "../../../interfaces/contacts";
import CommonLayout from "../../../layout/common";
import { TabPanel } from "../../../ui/tab-panel";

import "./index.scss";
import { UserContext } from "../../../context/user/UserContext";

type ContactParams = {
    id: string;
};

export const EditContact = () => {
    const { id } = useParams<ContactParams>();
    const { user, organization } = useContext(UserContext);
    const { getContactById, loading } = useContext(ContactsContext);
    const { getRequirementsByContact, loading: requirementsLoading } = useContext(RequirementsContext);
    const { getSubmissionsByContact, loading: submissionsLoading } = useContext(SubmissionsContext);
    const { getEmailsByContact, loading: emailsLoading } = useContext(EmailsContext);
    const [contact, setContact] = useState<Contact | undefined | null>(undefined);
    const [requirements, setRequirements] = useState<RequirementsResponse[] | undefined>(undefined);
    const [submissions, setSubmissions] = useState<SubmissionsResponse[] | undefined>(undefined);
    const [emails, setEmails] = useState<EmailsResponse[] | undefined>(undefined);
    const [tab, setTab] = useState(0);
    const [loadingFollowUp, setLoadingFollowUp] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const getContact = async () => {
        const resp = await getContactById(id);

        setContact(resp);
    };

    const getContactRequirements = async (id: string) => {
        const reqs = await getRequirementsByContact(id);

        setRequirements(reqs);
    };
    const getContactSubmissions = async (id: string) => {
        const subs = await getSubmissionsByContact(id);

        setSubmissions(subs);
    };
    const getContactEmails = async (id: string) => {
        const emails = await getEmailsByContact(id);

        setEmails(emails);
    };

    useEffect(() => {
        if (id) {
            window.analytics.track("view_edit_contacts", {
                id_user: user?.id,
                id_organization: organization?.id_organization,
            });

            getContact();
            getContactRequirements(id);
            getContactSubmissions(id);
            getContactEmails(id);
        }
    }, [id, tab]);

    const handleUpdateRow = async () => {
        getContactSubmissions(id);
        getContactRequirements(id);
        getContactEmails(id);
    };

    const handleSendFollowUp = async (id: string, id_organization: string) => {
        try {
            setLoadingFollowUp(id);
            await api.post(`/contacts/${id}/${id_organization}/send_follow_up`);
            setLoadingFollowUp("");
            enqueueSnackbar("Follow up enviado con éxito", {
                variant: "success",
            });
        } catch (e: any) {
            setLoadingFollowUp("");
            enqueueSnackbar(e?.response?.data.message, {
                variant: "error",
            });
        }
    };

    const sendFollowUp = (ev: React.MouseEvent) => {
        ev.stopPropagation();
        if (contact) {
            window.analytics.track(`click_${eventName[tab]}_followUp_button_contacts`, {
                id_user: user?.id,
                id_organization: organization?.id_organization,
            });
            handleSendFollowUp(contact.id_contact, contact.id_organization);
        }
    };

    const eventName = ["detail", "requirements", "submissions", "emails"];

    return (
        <div className="pages-edit-contact">
            <CommonLayout loading={loading} title="Editar Contacto">
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Tabs
                            value={tab}
                            onChange={(ev, newValue) => {
                                window.analytics.track(`click_${eventName[newValue]}_button_contacts`, {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                });
                                setTab(newValue);
                            }}
                        >
                            <Tab label="Detalle" />
                            <Tab label="Requerimientos" />
                            <Tab label="Recepciones" />
                            <Tab label="Emails" />
                        </Tabs>
                        <div>
                            <Button
                                disabled={Boolean(loadingFollowUp)}
                                size="small"
                                sx={{ height: "37px" }}
                                variant="outlined"
                                onClick={sendFollowUp}
                            >
                                <p style={{ paddingRight: "5px" }}>Follow up</p>
                                {loadingFollowUp ? (
                                    <CircularProgress size={15} sx={{ marginRight: "5px" }} />
                                ) : (
                                    <MailOutlinedIcon sx={{ fontSize: "20px" }} />
                                )}
                            </Button>
                        </div>
                    </Box>
                    <TabPanel index={0} value={tab}>
                        {contact && <ContactForm contact={contact} />}
                    </TabPanel>
                    <TabPanel index={1} value={tab}>
                        <div>
                            {!requirements && <CircularProgress />}
                            {requirements && requirements.length > 0 ? (
                                <RequirementsTable
                                    isDetailSection
                                    handleUpdateRow={handleUpdateRow}
                                    requirements={requirements}
                                />
                            ) : (
                                "No hay requerimientos"
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel index={2} value={tab}>
                        <div>
                            {!submissions && <CircularProgress />}
                            {submissions && submissions.length > 0 ? (
                                <SubmissionsTable isDetailSection submissions={submissions} />
                            ) : (
                                "No hay recepciones"
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel index={3} value={tab}>
                        <div>
                            {!emails && <CircularProgress />}
                            {emails && emails.length > 0 ? (
                                <EmailsTable isDetailSection emails={emails} />
                            ) : (
                                "No hay emails"
                            )}
                        </div>
                    </TabPanel>
                </Box>
            </CommonLayout>
        </div>
    );
};
