import React, { useState } from "react";

import Dropzone from "react-dropzone";
import { RouteComponentProps } from "react-router";
import { Attachment } from "../../interfaces/api/submissions";

import { FilePreview } from "../../ui/file-preview";

import "./index.scss";

interface UploadFileType {
    files: File[] | Attachment[];
    setFiles: any;
}

const UploadFiles = (props: UploadFileType) => {
    const { files, setFiles } = props;
    const [data, setData] = useState();

    const handleDrop = (acceptedFiles: File[]) => {
        setFiles([...files, ...acceptedFiles]);
    };

    /* const getAcceptedTypesText = () => {
        const extensions = mimeTypes?.map((e)=> (e.split('/')[1])); 
        const acceptedExtensions= extensions?.slice(0, -1).join(',')+' and '+ extensions?.slice(-1);
        return (`Only ${acceptedExtensions} will be accepted.`);
    } */

    /* const getMaxFilesText = () => {
        if(requirement.template.config.max_quantity) {
            return (` Maximum of ${requirement.template.config.max_quantity} files.`);
        }
        return "";
    } */

    const handleDataChange = ({ data, errors }: any) => {
        setData(data);
    };

    const handleDeleteFile = (deletedIndex: number) => () => {
        setFiles((files: File[]) => files.filter((f: File, index: number) => index !== deletedIndex));
    };

    return (
        <div className="upload-files">
            <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>
                                Arrastre y suelte algunos archivos aquí, o haga click para seleccionarlos manualmente.
                            </p>
                        </div>
                    </section>
                )}
            </Dropzone>
            <div className="file-container">
                {files.length > 0 &&
                    files.map((f, index) => <FilePreview key={index} file={f} onDelete={handleDeleteFile(index)} />)}
            </div>
        </div>
    );
};

export default UploadFiles;
