import React, { useContext, useEffect, useState } from "react";

import { SystemUpdateAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, GridRowParams, GridSortModel, GridValueGetterParams } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { RouteComponentProps, withRouter } from "react-router";
import useSWR from "swr";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import api from "../../api";
import ImportPreviewDialog from "../../components/import-preview";
import { ContactsContext } from "../../context/contacts/ContactsContext";
import { Contact } from "../../interfaces/contacts";
import CommonLayout from "../../layout/common";
import { SearchInput } from "../../ui/search-input";
import { Table } from "../../ui/table";

import "./index.scss";
import { UserContext } from "../../context/user/UserContext";
import { DialogContext } from "../../context/dialog/DialogContext";

const fetcher = (url: string) => api.get(url).then((res) => res.data);

type PropTypes = RouteComponentProps<any>;

let timerId: NodeJS.Timeout;

const Contacts = (props: PropTypes) => {
    const [importData, setImportData] = useState<Contact[] | undefined>();
    const [customColumns, setCustomColumns] = useState<GridColDef[] | undefined>();
    const [loadingFollowUp, setLoadingFollowUp] = useState("");
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: "first_name",
            sort: "asc",
        },
    ]);

    const {
        contacts,
        getContacts,
        searchContacts,
        getContactsCount,
        deleteContact,
        count,
        errorMessage,
        createContact,
        loading,
        pageSize,
        pageIndex,
        setPageIndex,
        setPageSize,
        searchText,
        setSearchText,
    } = useContext(ContactsContext);
    const { openDialog: openCustomDialog, closeDialog: closeCustomDialog } = useContext(DialogContext);
    const { user, organization } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [searchedText, setSearchedText] = useState<string | undefined>(searchText);

    const columns: GridColDef[] = [
        {
            field: "first_name",
            headerName: "Nombre",
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.first_name || ""} ${params.row.middle_name || ""}`,
        },
        {
            field: "last_name",
            headerName: "Apellido",
            width: 200,
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
            minWidth: 200,
        },
        {
            field: "phone",
            headerName: "Teléfono",
            sortable: false,
            width: 200,
        },
        {
            field: "accounts",
            headerName: "Cuentas",
            width: 200,
        },
        {
            field: "actions",
            headerName: "Acciones",
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params: any) => (
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Tooltip title="Editar contacto">
                        <IconButton
                            onClick={(ev: any) => {
                                ev.stopPropagation();
                                window.analytics.track("click_edit_button_contacts", {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                });
                                handleEditContact(params.row.id_contact);
                            }}
                        >
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar follow up">
                        <IconButton
                            onClick={(ev: any) => {
                                ev.stopPropagation();
                                window.analytics.track("click_follow_up_button_contacts", {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                });
                                handleSendFollowUp(params.row.id_contact, params.row.id_organization);
                            }}
                        >
                            {loadingFollowUp !== params.row.id_contact ? (
                                <Icon>refresh</Icon>
                            ) : (
                                <CircularProgress size={14} />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Borrar contacto">
                        <IconButton
                            onClick={(ev: any) => {
                                ev.stopPropagation();
                                window.analytics.track("click_delete_button_contacts", {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                });
                                handleDeleteContact(params.row.id_contact);
                            }}
                        >
                            {loadingFollowUp !== params.row.id_contact ? (
                                <Icon>delete</Icon>
                            ) : (
                                <CircularProgress size={14} />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const handleAddContact = () => {
        props.history.push("/contacts/new");
    };

    const handleEditContact = (id: string) => {
        props.history.push(`/contacts/${id}`);
    };

    const handleSendFollowUp = async (id: string, id_organization: string) => {
        try {
            setLoadingFollowUp(id);
            await api.post(`/contacts/${id}/${id_organization}/send_follow_up`);
            setLoadingFollowUp("");
            enqueueSnackbar("Follow up enviado con éxito", {
                variant: "success",
            });
        } catch (e: any) {
            setLoadingFollowUp("");
            enqueueSnackbar(e?.response?.data.message, {
                variant: "error",
            });
        }
    };

    let basicUrl = "/contacts?";

    if (searchText) {
        basicUrl = `/contacts/search?q=${searchText}&`;
    }
    if (sortModel && sortModel[0]) {
        basicUrl = basicUrl + `&order=${sortModel[0].field}.${sortModel[0].sort}&`;
    }
    const { data, error } = useSWR(`${basicUrl}limit=${pageSize}&offset=${pageIndex * pageSize}`, fetcher);
    const { data: preFetch, error: preFetchError } = useSWR(
        count && count > (pageIndex + 1) * pageSize
            ? `${basicUrl}limit=${pageSize}&offset=${(pageIndex + 1) * pageSize}`
            : null,
        fetcher,
    );
    const { data: postFetch, error: postFetchError } = useSWR(
        pageIndex > 0 ? `${basicUrl}limit=${pageSize}&offset=${(pageIndex - 1) * pageSize}` : null,
        fetcher,
    );

    useEffect(() => {
        getContactsCount();
    }, [searchText]);

    const importContacts = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files,
            f = files && files[0];
        const reader = new FileReader();

        if (f) {
            reader.onload = function (e: ProgressEvent<FileReader>) {
                const data = e?.target?.result;
                const readedData = XLSX.read(data, { type: "binary" });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const xlsxData = XLSX.utils.sheet_to_json(ws, { header: "A", raw: false, rawNumbers: false });

                if (xlsxData && xlsxData instanceof Array) {
                    const dataParse = xlsxData.slice(1).map(
                        (o: any): Contact => {
                            const temp: any = {};

                            Object.keys(o).map((k) => (temp[xlsxData[0][k]] = o[k]));

                            return {
                                id_contact: uuidv4(),
                                ...temp,
                                accounts: (temp.accounts || "").split(", "),
                            };
                        },
                    );
                    const customColumns: GridColDef[] = [];

                    Object.keys(xlsxData[0]).map((key) => {
                        const column = columns.find((c) => c.field === xlsxData[0][key]);

                        if (
                            !["first_name", "middle_name", "email", "phone", "last_name"].some(
                                (a) => a === xlsxData[0][key],
                            )
                        ) {
                            customColumns.push({
                                field: xlsxData[0][key],
                                width: 200,
                            });
                        } else if (["first_name", "middle_name"].includes(xlsxData[0][key])) {
                            customColumns.push({
                                field: "first_name",
                                headerName: "Nombre",
                                width: 200,
                                valueGetter: (params: GridValueGetterParams) =>
                                    `${params.row.first_name || ""} ${params.row.middle_name || ""}`,
                            });
                        } else if (column) {
                            customColumns.push(column);
                        }
                    });
                    setCustomColumns(customColumns);
                    setImportData(dataParse);
                }
            };
            reader.readAsBinaryString(f);
        }
        e.target.value = "";
    };

    const handleChangeSearch = (searchedText: string) => {
        if (searchedText.length) {
            setSearchedText(searchedText);
        } else {
            setSearchedText(undefined);
        }
    };

    useEffect(() => {
        clearTimeout(timerId);

        if (searchedText && searchedText.length > 2) {
            timerId = setTimeout(function () {
                /* searchContacts(searchedText); */
                setPageIndex(0);
                setSearchText(searchedText);
                clearTimeout(timerId);
            }, 500);
        } else if (!searchedText || searchedText === "") {
            timerId = setTimeout(function () {
                setPageIndex(0);
                setSearchText("");
                /* getContacts(); */
                clearTimeout(timerId);
            }, 500);
        }
    }, [searchedText]);

    const handleClickSearch = () => {
        clearTimeout(timerId);

        if (searchedText && searchedText.length > 2) {
            timerId = setTimeout(function () {
                /* searchContacts(searchedText); */
                setPageIndex(0);
                setSearchText(searchedText);
                clearTimeout(timerId);
            }, 500);
        } else if (!searchedText || searchedText === "") {
            timerId = setTimeout(function () {
                setPageIndex(0);
                setSearchText("");
                /* getContacts(); */
                clearTimeout(timerId);
            }, 500);
        }
    };

    interface DialogPropTypes {
        id_contact: string;
    }

    const DialogComponent = (props: DialogPropTypes) => {
        const [loading, setLoading] = useState(false);

        return (
            <DialogContent sx={{ paddingBottom: "8px" }}>
                <DialogTitle id="alert-dialog-description">¿Estás seguro que quieres borrar este contacto?</DialogTitle>
                <DialogActions>
                    {loading && <CircularProgress size={18} />}
                    <Button autoFocus disabled={loading} onClick={() => closeCustomDialog()}>
                        No
                    </Button>
                    <Button
                        color="primary"
                        disabled={loading}
                        variant="outlined"
                        onClick={async () => {
                            setLoading(true);
                            const success = await deleteContact(props.id_contact);

                            if (success) {
                                closeCustomDialog();
                            }
                            setLoading(false);
                        }}
                    >
                        Sí
                    </Button>
                </DialogActions>
            </DialogContent>
        );
    };

    const handleDeleteContact = (id_contact: string) => {
        openCustomDialog({
            children: <DialogComponent id_contact={id_contact} />,
        });
    };

    return (
        <div className="pages-contacts">
            <CommonLayout loading={(!data && !error) || loading} title="Contactos">
                <div className="buttons-wrapper">
                    <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
                        <Button
                            startIcon={<AddIcon sx={{ fontWeight: 300 }} />}
                            sx={{ marginRight: "16px", alignItems: "center", padding: "2px 16px" }}
                            variant="contained"
                            onClick={handleAddContact}
                        >
                            Agregar
                        </Button>
                        <Button
                            startIcon={<SystemUpdateAlt sx={{ fontWeight: 300 }} />}
                            sx={{ marginRight: "16px", alignItems: "center", padding: "2px 16px" }}
                            variant="contained"
                            onClick={() =>
                                window.analytics.track("click_import_button_contacts", {
                                    id_user: user?.id,
                                    id_organization: organization?.id_organization,
                                })
                            }
                        >
                            <input
                                hidden
                                accept={[".csv", ".xlsx", ".xls", "text/plain"].join(",")}
                                className="hidden"
                                id="import-contact-input"
                                type="file"
                                onChange={importContacts}
                            />
                            <label htmlFor="import-contact-input">Importar</label>
                        </Button>
                    </Box>
                    <SearchInput value={searchedText} onChange={handleChangeSearch} onClickSearch={handleClickSearch} />
                </div>
                <Table
                    pagination
                    columns={columns}
                    getRowId={(row: any) => row["id_contact"]}
                    initialSortModel={[]}
                    loading={(!data && !error) || loading}
                    page={pageIndex}
                    pageSize={pageSize}
                    paginationMode="server"
                    rowCount={count}
                    rows={data || []}
                    sortModel={sortModel}
                    sortingMode="server"
                    onPageChange={(newPage: number) => setPageIndex(newPage)}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    onRowClick={(params: GridRowParams, ev: any) => {
                        ev.stopPropagation();
                        window.analytics.track("click_row_contacts", {
                            id_user: user?.id,
                            id_organization: organization?.id_organization,
                        });
                        handleEditContact(params.row.id_contact);
                    }}
                    onSortModelChange={(sort: GridSortModel) => setSortModel(sort)}
                />
                <ImportPreviewDialog
                    cleanData={() => setImportData(undefined)}
                    columns={customColumns || []}
                    completePreview={async () => {
                        if (importData) {
                            const success = await createContact(importData);

                            if (success) {
                                setImportData(undefined);
                            }
                        }
                    }}
                    data={importData}
                    loading={loading}
                />
            </CommonLayout>
        </div>
    );
};

export default withRouter(Contacts);
