import { TemplateResponse } from "../../interfaces/api/templates";
import { Template } from "../../interfaces/templates";

export interface TemplatesState {
    errorMessage?: string;
    loading: boolean;
    templates?: TemplateResponse[];
    searchTemplate: string;
    defaultTemplate?: Partial<Template> | undefined;
    template?: Template | undefined;
}

type TemplatesActions =
    | { type: "setTemplates"; payload: TemplateResponse[] | undefined }
    | { type: "addError"; payload: string }
    | { type: "setLoading"; payload: boolean }
    | { type: "setSearchTemplate"; payload: string }
    | { type: "setDefaultTemplate"; payload: Partial<Template> }
    | { type: "setTemplate"; payload: Template }
    | { type: "cleanTemplates" }
    | { type: "removeError" };

export const TemplatesReducer = (state: TemplatesState, action: TemplatesActions): TemplatesState => {
    switch (action.type) {
        case "setLoading":
            return {
                ...state,
                loading: action.payload,
            };
        case "addError":
            return {
                ...state,
                errorMessage: action.payload,
            };
        case "removeError":
            return {
                ...state,
                errorMessage: undefined,
            };
        case "setSearchTemplate":
            return {
                ...state,
                searchTemplate: action.payload,
            };
        case "setTemplates":
            return {
                ...state,
                templates: action.payload,
            };
        case "setTemplate":
            return {
                ...state,
                template: action.payload,
            };
        case "cleanTemplates":
            return {
                ...state,
                templates: undefined,
            };
        case "setDefaultTemplate":
            return {
                ...state,
                defaultTemplate: action.payload,
            };
        default:
            return state;
    }
};
