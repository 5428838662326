import React, { createContext, useReducer } from "react";

import { isArray } from "lodash";
import { useSnackbar } from "notistack";

import api from "../../api";
import { RequirementsResponse } from "../../interfaces/api/requirements";
import { Requirement, RequirementImportExcel } from "../../interfaces/requirements";
import { RequirementsReducer, RequirementsState } from "./RequirementsReducer";

type RequirementsContextProps = {
    requirements?: RequirementsResponse[];
    activeRequirements?: RequirementsResponse[];
    inactiveRequirements?: RequirementsResponse[];
    expiredRequirements?: RequirementsResponse[];
    errorMessage?: string;
    loading: boolean;
    loadingAction: {
        value: boolean;
        type?: string;
        reqId?: string;
    };
    filter?: "active" | "inactive" | "expired" | undefined;
    getRequirements: () => Promise<void>;
    getRequirementsByFilter: (v: "active" | "inactive" | "expired") => Promise<void>;
    getRequirementById: (id: string) => Promise<Requirement | undefined>;
    getRequirementsByContact: (contactId: string) => Promise<RequirementsResponse[] | undefined>;
    getRequirementsByTemplate: (contactId: string) => Promise<RequirementsResponse[] | undefined>;
    removeError: () => void;
    cleanRequirements: () => void;
    createRequirement: (requirement: Partial<Requirement> | RequirementImportExcel[], by: string) => Promise<boolean>;
    updateRequirement: (requirement: Partial<Requirement>, id: string) => Promise<boolean>;
    cancelRequirement: (id: string) => Promise<void>;
    pauseRequirement: (id: string) => Promise<void>;
    resumeRequirement: (id: string) => Promise<void>;
    restartRequirement: (id: string) => Promise<void>;
    completeRequirement: (id: string) => Promise<void>;
};

const requirementsInitialState: RequirementsState = {
    loading: false,
    loadingAction: {
        value: false,
        type: undefined,
    },
    errorMessage: undefined,
    requirements: undefined,
    filter: undefined,
};

export const RequirementsContext = createContext({} as RequirementsContextProps);

export const RequirementsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(RequirementsReducer, requirementsInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = (action: boolean) => {
        dispatch({
            type: "removeError",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
    };

    const onSuccessWithMessage = (action: boolean, successMessage: string) => {
        onSuccess(action);
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (action: boolean, errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        if (action) {
            dispatch({
                type: "setLoadingAction",
                payload: { value: false, type: undefined, reqId: undefined },
            });
        } else {
            dispatch({
                type: "setLoading",
                payload: false,
            });
        }
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getRequirements = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<RequirementsResponse[]>("/requirements");
            const requirements = resp.data;

            dispatch({
                type: "setRequirements",
                payload: requirements || undefined,
            });
            onSuccess(false);
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            cleanRequirements();
        }
    };

    const getRequirementById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<RequirementsResponse>(`/requirements/${id}`);
            const requirement = resp.data;
            const mappedRequirement: Requirement = {
                id_requirement: requirement.id_requirement,
                template: requirement.template,
                contact: requirement.contact,
                data: requirement.data,
                reference: requirement.reference,
                created_at: requirement.created_at,
                updated_at: requirement.updated_at,
            };

            onSuccess(false);

            return mappedRequirement;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getRequirementsByContact = async (contactId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<RequirementsResponse[]>(
                `/requirements?contact->>id_contact=eq.${contactId}`,
            );

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const getRequirementsByFilter = async (status: "active" | "inactive" | "expired") => {
        dispatch({
            type: "setFilter",
            payload: status || undefined,
        });
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });

            if (status === "active") {
                const { data } = await api.get<RequirementsResponse[]>(
                    "/requirements?or=(status.eq.PENDING,status.eq.REVIEW)",
                );

                dispatch({
                    type: "setActiveRequirements",
                    payload: data || undefined,
                });
            } else if (status === "inactive") {
                const { data } = await api.get<RequirementsResponse[]>(
                    "/requirements?or=(status.eq.COMPLETED,status.eq.CANCELLED,status.eq.PAUSED,status.eq.EXPIRED)",
                );

                dispatch({
                    type: "setInactiveRequirements",
                    payload: data || undefined,
                });
            } else {
                const { data } = await api.get<RequirementsResponse[]>("/requirements?status=eq.EXPIRED");

                dispatch({
                    type: "setexpiredRequirements",
                    payload: data || undefined,
                });
            }
            onSuccess(false);
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const getRequirementsByTemplate = async (templateId: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const { data } = await api.get<RequirementsResponse[]>(
                `/requirements?template->>id_template=eq.${templateId}`,
            );

            onSuccess(false);

            return data;
        } catch (error: any) {
            onError(false, error.response?.data?.message);

            return;
        }
    };

    const createRequirement = async (requirement: Partial<Requirement> | RequirementImportExcel[], by: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const mapRequirement = () => {
                if (isArray(requirement)) {
                    return requirement.map((r) => ({ ...r, id_requirement: undefined }));
                } else {
                    return {
                        ...requirement,
                        id_requirement: undefined,
                        contact: undefined,
                        template: undefined,
                        id_contact: requirement.contact?.id_contact,
                        id_template: requirement.template?.id_template,
                    };
                }
            };
            const mappedRequirement = mapRequirement();

            if (by === "accounts") {
                await api.post<RequirementsResponse[]>("/requirements_from_accounts", mappedRequirement);
            } else {
                await api.post<RequirementsResponse[]>("/requirements", mappedRequirement);
            }
            onSuccessWithMessage(false, "El requerimiento ha sido creado correctamente");
            getRequirements();
            const success = true;

            return success;
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const updateRequirement = async (requirement: Partial<Requirement>, id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            await api.patch<RequirementsResponse[]>(`/requirements/${id}`, requirement);
            onSuccessWithMessage(false, "El requerimiento ha sido actualizado correctamente");
            if (state.filter) {
                getRequirementsByFilter(state.filter);
            } else {
                getRequirements();
            }
            const success = true;

            return success;
        } catch (error: any) {
            onError(false, error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const cancelRequirement = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "cancel", reqId: id },
            });
            await api.post(`/requirements/${id}/cancel`);
            onSuccessWithMessage(true, "El requerimiento ha sido cancelado correctamente");
            getRequirements();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const pauseRequirement = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "pause", reqId: id },
            });
            await api.post(`/requirements/${id}/pause`);
            onSuccessWithMessage(true, "El requerimiento ha sido pausado correctamente");
            getRequirements();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const completeRequirement = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "complete", reqId: id },
            });
            await api.post(`/requirements/${id}/complete`);
            onSuccessWithMessage(true, "El requerimiento ha sido completado correctamente");
            getRequirements();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const resumeRequirement = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "resume", reqId: id },
            });
            await api.post(`/requirements/${id}/resume`);
            onSuccessWithMessage(true, "El requerimiento ha sido reanudado correctamente");
            getRequirements();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };

    const restartRequirement = async (id: string) => {
        try {
            dispatch({
                type: "setLoadingAction",
                payload: { value: true, type: "restart", reqId: id },
            });
            await api.post(`/requirements/${id}/restart`);
            onSuccessWithMessage(true, "El requerimiento ha sido reiniciado correctamente");
            getRequirements();
        } catch (error: any) {
            onError(true, error.response?.data?.message);
        }
    };
    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const cleanRequirements = () => {
        dispatch({ type: "cleanRequirements" });
    };

    return (
        <RequirementsContext.Provider
            value={{
                ...state,
                getRequirements,
                getRequirementsByFilter,
                cleanRequirements,
                removeError,
                createRequirement,
                updateRequirement,
                getRequirementById,
                getRequirementsByContact,
                getRequirementsByTemplate,
                cancelRequirement,
                completeRequirement,
                pauseRequirement,
                resumeRequirement,
                restartRequirement,
            }}
        >
            {children}
        </RequirementsContext.Provider>
    );
};
