import React, { createContext, useReducer } from "react";

import { useSnackbar } from "notistack";

import api from "../../api";
import { TemplateResponse, TemplateTypes } from "../../interfaces/api/templates";
import { Template } from "../../interfaces/templates";
import { TemplatesReducer, TemplatesState } from "./TemplatesReducer";

type TemplatesContextProps = {
    templates?: TemplateResponse[];
    defaultTemplate?: Partial<Template> | undefined;
    template?: Template | undefined;
    errorMessage?: string;
    loading: boolean;
    searchTemplate: string;
    getTemplates: () => Promise<void>;
    getTemplateById: (id: string) => Promise<Template | undefined>;
    removeError: () => void;
    cleanTemplates: () => void;
    createTemplate: (template: Partial<Template>) => Promise<boolean>;
    updateTemplate: (template: Partial<Template>, id: string) => Promise<boolean>;
    setSearchTemplate: (searchText: string) => void;
    setDefaultTemplate: (defaultTemplate: Partial<Template>) => void;
};

const templatesInitialState: TemplatesState = {
    loading: false,
    errorMessage: undefined,
    templates: undefined,
    template: undefined,
    defaultTemplate: undefined,
    searchTemplate: "",
};

export const TemplatesContext = createContext({} as TemplatesContextProps);

export const TemplatesProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(TemplatesReducer, templatesInitialState);
    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = () => {
        dispatch({
            type: "removeError",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
    };

    const onSuccessWithMessage = (successMessage: string) => {
        onSuccess();
        enqueueSnackbar(successMessage, {
            variant: "success",
        });
    };

    const onError = (errorMsg?: string) => {
        dispatch({
            type: "addError",
            payload: errorMsg || "Hubo un error",
        });
        dispatch({
            type: "setLoading",
            payload: false,
        });
        enqueueSnackbar(errorMsg || "Hubo un error", {
            variant: "error",
        });
    };

    const getTemplates = async () => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<TemplateResponse[]>("/templates");
            const templates = resp.data;

            dispatch({
                type: "setTemplates",
                payload: templates || undefined,
            });
            onSuccess();
        } catch (error: any) {
            onError(error.response?.data?.message);
            cleanTemplates();
        }
    };

    const getTemplateById = async (id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            const resp = await api.get<TemplateResponse>(`/templates/${id}`);
            const template = resp.data;
            const mappedTemplate: Template = {
                id_template: template.id_template,
                name: template.name,
                type: template.type,
                instructions: template.instructions,
                expiration_days: template.expiration_days,
                frequency_days: template.frequency_days,
                config: template.config,
                data_example: template.data_example,
            };

            onSuccess();
            dispatch({ type: "setTemplate", payload: mappedTemplate });

            return mappedTemplate;
        } catch (error: any) {
            onError(error.response?.data?.message);

            return;
        }
    };

    const createTemplate = async (template: Partial<Template>) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            let config = typeof template.config === "string" ? JSON.parse(template.config) : template.config;

            if (template.type === TemplateTypes.FormSignature || template.type === TemplateTypes.Signature) {
                config = {
                    ...config,
                    legal_text: template.legal_text,
                };
            }
            await api.post<TemplateResponse[]>("/templates", {
                ...template,
                id_template: undefined,
                legal_text: undefined,
                config,
            });
            onSuccessWithMessage("El template ha sido creado correctamente");
            const success = true;

            return success;
        } catch (error: any) {
            onError(error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const updateTemplate = async (template: Partial<Template>, id: string) => {
        try {
            dispatch({
                type: "setLoading",
                payload: true,
            });
            let config = typeof template.config === "string" ? JSON.parse(template.config) : template.config;

            if (template.type === TemplateTypes.FormSignature || template.type === TemplateTypes.Signature) {
                config = {
                    ...config,
                    legal_text: template.legal_text,
                };
            }
            await api.patch<TemplateResponse[]>(`/templates/${id}`, {
                ...template,
                legal_text: undefined,
                config,
            });
            onSuccessWithMessage("El template ha sido actualizado correctamente");
            const success = true;

            await getTemplateById(id);

            return success;
        } catch (error: any) {
            onError(error.response?.data?.message);
            const success = false;

            return success;
        }
    };

    const removeError = () => {
        dispatch({ type: "removeError" });
    };

    const setSearchTemplate = (searchText: string) => {
        dispatch({ type: "setSearchTemplate", payload: searchText });
    };

    const cleanTemplates = () => {
        dispatch({ type: "cleanTemplates" });
    };

    const setDefaultTemplate = (defaultTemplate: Partial<Template>) => {
        dispatch({ type: "setDefaultTemplate", payload: defaultTemplate });
    };

    return (
        <TemplatesContext.Provider
            value={{
                ...state,
                setDefaultTemplate,
                getTemplates,
                cleanTemplates,
                removeError,
                createTemplate,
                updateTemplate,
                getTemplateById,
                setSearchTemplate,
            }}
        >
            {children}
        </TemplatesContext.Provider>
    );
};
